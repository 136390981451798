import React from "react";
import { Box, SimpleGrid, Heading, Text } from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";

import Form from "./Form";
import ContactDetails from "./presentation/ContactDetails";

const query = graphql`
  {
    componentsYaml(layout: { eq: "contact" }) {
      layout
      title
      subtitle
      options {
        text
        value
        type
      }
    }
  }
`;

const ContactForm = () => {
  const {
    componentsYaml: { layout, title, subtitle, options },
  } = useStaticQuery(query);
  return (
    <Box px={6} py={10} mb={4} maxW='4xl' mx='auto'>
      <Box mt={6} mb={12} maxW='md'>
        <Heading>{title}</Heading>
        <Text color='gray.500'>{subtitle}</Text>
      </Box>
      <SimpleGrid
        id={layout}
        columns={{ base: 1, sm: 2 }}
        columnGap={{ sm: 10, md: 24 }}
      >
        <Form />
        <ContactDetails title={title} subtitle={subtitle} options={options} />
      </SimpleGrid>
    </Box>
  );
};

export default ContactForm;
