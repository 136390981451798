import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { Box } from "@chakra-ui/react";

export default function ImageList({ content }) {
  return content.map(({ title, alt, logo }, i) => (
    <Box key={i} w={{ base: 20, md: 32, lg: 40 }}>
      <GatsbyImage
        image={logo}
        alt={alt ? alt : `${title} logo`}
        title={title}
      />
    </Box>
  ));
}
