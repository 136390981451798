import React from "react";
import {
  chakra,
  Box,
  SimpleGrid,
  Flex,
  useColorModeValue,
  Icon
} from "@chakra-ui/react";

import MarkdownParser from '../util/MarkdownParser';
import HeadingComponent from './Heading';

const WarningIcon = () => (
  <g>
    <path d="M8 1.45l6.705 13.363h-13.409l6.705-13.363zM8 0c-0.345 0-0.69 0.233-0.951 0.698l-6.829 13.611c-0.523 0.93-0.078 1.691 0.989 1.691h13.583c1.067 0 1.512-0.761 0.989-1.691h0l-6.829-13.611c-0.262-0.465-0.606-0.698-0.951-0.698v0z" />
    <path d="M9 13c0 0.552-0.448 1-1 1s-1-0.448-1-1c0-0.552 0.448-1 1-1s1 0.448 1 1z" />
    <path d="M8 11c-0.552 0-1-0.448-1-1v-3c0-0.552 0.448-1 1-1s1 0.448 1 1v3c0 0.552-0.448 1-1 1z" />
  </g>
);

const Feature = ({ icon, color, title, children }) => (
  <Box>
    <Flex
      alignItems="center"
      justifyContent="center"
      w={8}
      h={8}
      mb={4}
      rounded="full"
      color={useColorModeValue(`${color}.600`, `${color}.100`)}
      bg={useColorModeValue(`${color}.100`, `${color}.600`)}
    >
      <Icon
        boxSize={5}
        viewBox="0 0 24 24"
        fill="currentColor"
        aria-hidden="true"
      >
        {icon ? <g dangerouslySetInnerHTML={{ __html: icon }} /> : <WarningIcon />}
      </Icon>
    </Flex>
    <chakra.h3
      mb={2}
      fontWeight="semibold"
      lineHeight="shorter"
      color={useColorModeValue("gray.900")}
    >
      {title}
    </chakra.h3>
    {children}
  </Box>
);
const colours = ["red", "pink", "yellow", "green", "purple", "blue", "brand"];
const COLOURS_LENGTH = colours.length;

export const FeaturesList = ({ title, subtitle, layout, features }) => (
  <Flex
    bg={useColorModeValue("gray.50", "gray.600")}
    py={{base: 2, md: 8, xl: 20}}
    px={{ base: 4, sm: 10, lg: 20 }}
    w="auto"
    justifyContent="center"
    alignItems="center"
    id={layout}
  >
    <Box
      px={8}
      py={{ base: 10, md: 20}}
      my={10}
      maxW="8xl"
      mx="auto"
      bg={useColorModeValue("white", "gray.800")}
      shadow="xl"
      rounded="xl"
    >
      <Box mb={{base: 10, md: 20}} display="grid" placeContent="center">
        <HeadingComponent title={title} subtitle={subtitle} />
      </Box>
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3 }}
        spacingX={{ base: 16, lg: 24 }}
        spacingY={{base: 12, md: 20}}
        my={6}
      >
        {features.map(({ title, body, icon = null}, i) => (
          <Feature
            color={colours[i % COLOURS_LENGTH]}
            title={title}
            icon={icon}
            key={i}
          >
            <MarkdownParser src={body} fontSize="sm" color="gray.500" />
          </Feature>
        ))}
      </SimpleGrid>
    </Box>
  </Flex>
);
