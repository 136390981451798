import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { FeaturesList } from './presentation/FeaturesList';

const query = graphql`
{
  componentsYaml(layout: {eq: "features"}) {
    layout
    title
    subtitle
    features {
      title
      body
      icon
    }
  }
}
`;

export default function Features() {
  const { componentsYaml: {layout, title, subtitle, features} } = useStaticQuery(query);
  return <FeaturesList layout={layout} title={title} subtitle={subtitle} features={features} />
};