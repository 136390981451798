import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import isEmail from "validator/es/lib/isEmail";

import { MdOutlineEmail } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import React, { useState } from "react";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const FormField = ({
  field,
  label,
  children,
  isRequired = false,
  errorMessage,
}) => (
  <FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
    <FormLabel htmlFor={field}>{label}</FormLabel>
    <InputGroup borderColor='gray.300'>{children}</InputGroup>
    {!!errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
  </FormControl>
);

const Form = () => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const toast = useToast();

  const postToast = (status, title, description) => {
    toast({
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
    });
  };

  const updateForm = ({ target }) => {
    setForm({ ...form, [target.name]: target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!errors["mail"]) {
      const formTag = e.target;

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": formTag.getAttribute("name"),
          ...form,
        }),
      })
        .then(() =>
          postToast(
            "success",
            "Message Sent Successfully.",
            "We'll get back to you as soon as we can!"
          )
        )
        .catch((error) =>
          postToast(
            "error",
            "An Error Occurred",
            `An error occurred while sending your message (Error: "${error}")`
          )
        );
    }
  };

  return (
    <Flex
      as='form'
      flexDir='column'
      justifyItems='center'
      gap={6}
      mt={{ base: 6, md: 0 }}
      name='contact'
      method='post'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type='hidden' name='form-name' value='contact' />
      <p hidden>
        <label>
          Don’t fill this out: <input name='bot-field' onChange={updateForm} />
        </label>
      </p>

      <FormField field='name' label='Your Name'>
        <InputLeftElement pointerEvents='none'>
          <BsPerson color='gray.800' />
        </InputLeftElement>
        <Input
          type='text'
          name='name'
          id='name'
          size='md'
          onChange={updateForm}
        />
      </FormField>

      <FormField
        field='mail'
        label='Email'
        isRequired={true}
        errorMessage={errors["mail"]}
      >
        <InputLeftElement pointerEvents='none'>
          <MdOutlineEmail color='gray.800' />
        </InputLeftElement>
        <Input
          type='text'
          id='mail'
          name='mail'
          size='md'
          onChange={updateForm}
          onBlur={() => {
            setErrors({
              mail:
                form["mail"] && !isEmail(form["mail"])
                  ? "You must enter a valid email"
                  : null,
            });
          }}
        />
      </FormField>

      <FormControl>
        <FormLabel htmlFor='message'>Message</FormLabel>
        <Textarea
          borderColor='gray.300'
          _hover={{ borderRadius: "gray.300" }}
          name='message'
          id='message'
          onChange={updateForm}
        />
        <FormErrorMessage>This field must not be left blank</FormErrorMessage>
      </FormControl>

      <FormControl id='submit' float='right'>
        <Button
          bgGradient='linear(to-br, brand.400, brand.500)'
          color='white'
          colorScheme='brand'
          variant='solid'
          type='submit'
        >
          Send Message
        </Button>
      </FormControl>
    </Flex>
  );
};
export default Form;
