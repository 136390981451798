import React from 'react';

import { Box } from '@chakra-ui/react';

import Seo from '../components/util/SEO';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import Processes from '../components/Processes';
import Advantages from '../components/Advantages';
import Features from '../components/Features';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Testimonials from '../components/Testimonials';

const IndexPage = () => (
  <>
    <Seo />
    <NavBar />
    <Box id='welcome'>
      <Hero />
      <Processes />
      <Advantages />
      <Features />
      <Testimonials />
      <ContactForm />
      <Footer />
    </Box>
  </>
);

export default IndexPage;