import {
  Flex,
  Box,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";

import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";

import React from "react";

const copy = (text) => navigator.clipboard.writeText(text);

const icons = {
  address: MdLocationOn,
  email: MdEmail,
  phone: MdPhone,
};

const ContactDetail = ({ text, value, icon }) => {
  const Icon = icon;

  return (
    <Popover returnFocusOnClose={false} placement='bottom-start'>
      <PopoverTrigger>
        <Button
          variant='ghost'
          justifyContent='start'
          colorScheme='brand'
          py={2}
          px={3}
          rounded='lg'
          cursor='pointer'
          onClick={() => copy(value)}
        >
          <Flex gap={2} alignItems='center' sx={{ svg: { fill: "brand.400" } }}>
            <Icon />
            <Text>{text || value}</Text>
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        pointerEvents=''
        w='max-content'
        _focus={{ borderWidth: 1 }}
      >
        <PopoverArrow />
        <PopoverBody>🥳 Details copied!</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default function ContactDetails({ options }) {
  return (
    <Box maxW='md' order={{ base: -1, sm: 0 }}>
      <Text color='gray.500' fontStyle='italic' fontSize='sm'>
        Click below to copy the contact details.
      </Text>
      <Flex flexDir='column' gap={2} my={4} w='max-content'>
        {options.map(({ text, value, type }, i) => (
          <ContactDetail key={i} text={text} value={value} icon={icons[type]} />
        ))}
      </Flex>
    </Box>
  );
}
