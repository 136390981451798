import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import HeadingComponent from "./Heading";
import MarkdownParser from "../util/MarkdownParser";
import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";

const Item = ({
  title,
  subtitle,
  body,
  icon,
  image,
  fallback = <Box bg='gray.200' w={26} h={26} />,
  align = "left",
}) => (
  <SimpleGrid
    alignItems='center'
    columns={{ base: 1, md: 2 }}
    spacingY={{ base: 1, md: 32 }}
    spacingX={{ base: 10, md: 24 }}
    gridAutoFlow='row'
    h='max-content'
  >
    <Box order={{ base: 3, md: align === "left" ? 0 : 2 }}>
      <chakra.h2
        fontSize={{ base: "4xl", md: "5xl" }}
        fontWeight='800'
        letterSpacing='tight'
        textAlign={{ base: "center", md: "left" }}
        color={useColorModeValue("gray.700", "gray.400")}
        lineHeight='shorter'
      >
        {icon && (
          <Icon viewBox='0 0 24 24' mr={3} pb={1} fill='currentcolor'>
            <g dangerouslySetInnerHTML={{ __html: icon }} />
          </Icon>
        )}
        {title}
      </chakra.h2>
      <chakra.h3
        fontSize={{ base: "xl", md: "2xl" }}
        fontWeight='400'
        letterSpacing='tight'
        textAlign={{ base: "center", md: "left" }}
        color={useColorModeValue("gray.500", "gray.200")}
        lineHeight='shorter'
      >
        {subtitle}
      </chakra.h3>
      <MarkdownParser
        my={6}
        sx={{ "& p + p": { marginTop: "0.5em" } }}
        fontSize={{ base: "md", md: "lg" }}
        lineHeight={5}
        letterSpacing='tight'
        src={body}
      />
    </Box>

    <Flex
      flex={1}
      justifyContent='center'
      bg={useColorModeValue("gray.200", "gray.700")}
      rounded={"lg"}
    >
      <Box w={96} maxW='100%' maxH='100%'>
        {image ? <GatsbyImage image={image} alt='' /> : fallback}
      </Box>
    </Flex>
  </SimpleGrid>
);

export default function SideBySide({ title, subtitle, section, content }) {
  return (
    <Flex
      bg={useColorModeValue("gray.50", "gray.600")}
      py={{ base: 2, md: 8, xl: 20 }}
      px={{ base: 2, sm: 10, lg: 20 }}
      justifyContent='center'
      alignItems='center'
      mx='auto'
      id={section}
    >
      <Box
        shadow='xl'
        bg={useColorModeValue("white", "gray.800")}
        px={{ base: 4, md: 8 }}
        py={{ base: 10, md: 20 }}
        my={10}
        rounded='xl'
        maxW='8xl'
        mx='auto'
      >
        <Box mb={20} display='grid' placeContent='center'>
          <HeadingComponent title={title} subtitle={subtitle} />
        </Box>
        <SimpleGrid gap={{ base: 12, md: 32 }}>
          {content.map(
            ({ title, subtitle, icon, fallback, image, body, id }, i) => (
              <Item
                title={title}
                subtitle={subtitle}
                body={body}
                icon={icon}
                image={image}
                fallback={fallback}
                align={i % 2 === 0 ? "left" : "right"}
                key={i}
              />
            )
          )}
        </SimpleGrid>
      </Box>
    </Flex>
  );
}
