import React from "react";

import { Heading, Text, useColorModeValue } from "@chakra-ui/react";


const HeadingComponent = ({title, subtitle}) => (
    <>
        <Heading
          fontSize={{ base: "3xl", sm: "4xl", xl: "5xl" }}
          lineHeight="shorter"
          fontWeight="extrabold"
          letterSpacing="tight"
          maxW="3xl"
          display="inline-block"
          mx={{ lg: "auto" }}
          textAlign={{ base: "left", lg: "center" }}
          color={useColorModeValue("gray.900")}
          backgroundImage="linear-gradient(100deg, var(--chakra-colors-brand-300), var(--chakra-colors-brand-600))"
          backgroundClip="text"
          >
          {title}
        </Heading>
        <Text
          mt={2}
          maxW="3xl"
          mx={{ lg: "auto" }}
          fontSize={{ base: "lg", lg: "xl"}}
          lineHeight="shorter"
          fontWeight="400"
          letterSpacing="tight"
          textAlign={{ base: "left", lg: "center" }}
          color={useColorModeValue("gray.600", "gray.400")}
        >
          {subtitle}
        </Text>
    </>
);

export default HeadingComponent;