import React from "react";

import { Flex, Heading, Stack, Box, IconButton } from "@chakra-ui/react";
import MarkdownParser from "../util/MarkdownParser";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Hero({
  section,
  title,
  subtitle,
  image,
  fallback = <Box bg='gray.200' w={26} h={26} />,
  onChevronClick,
  chevronTitle,
  chevronSubtitle,
}) {
  return (
    <Box maxW='8xl' mt={{ base: 20, lg: 0 }} mx='auto' px={4}>
      <Stack
        minH={"xl"}
        h='max-content'
        direction={{ base: "column", md: "row" }}
        mx='auto'
        id={section}
      >
        <Flex
          px={{ base: 4, md: 8 }}
          flex={1}
          align={"center"}
          justify={"center"}
        >
          <Stack
            w={"full"}
            spacing={6}
            maxW={"xl"}
            sx={{
              ".hero-subtitle": {
                // color: 'brand.400'
              },
              ".hero-title em": {
                color: "brand.400",
                fontStyle: "normal",
                fontSize: "1.45em",
                fontWeight: 800,
                backgroundImage:
                  "linear-gradient(100deg, var(--chakra-colors-brand-300), var(--chakra-colors-brand-600))",
                backgroundClip: "text",
              },
            }}
          >
            <Heading
              fontSize={{ base: "3xl", md: "2xl", lg: "4xl" }}
              letterSpacing='tighter'
              fontWeight='400'
            >
              <MarkdownParser src={title} className='hero-title' />
            </Heading>
            <MarkdownParser
              src={subtitle}
              lineHeight='shorter'
              fontSize='lg'
              fontWeight='400'
              className='hero-subtitle'
            />
          </Stack>
        </Flex>
        <Flex flex={1} h='max-content' alignSelf='center'>
          {image ? <GatsbyImage image={image} alt='' /> : fallback}
        </Flex>
      </Stack>
      {(chevronTitle || chevronSubtitle) && (
        <Box mx='center' textAlign='center' mb={6}>
          {chevronTitle && (
            <Heading size='md' fontWeight={400}>
              {chevronTitle}
            </Heading>
          )}
          {chevronSubtitle && (
            <Heading size='lg' fontWeight={600}>
              {chevronSubtitle}
            </Heading>
          )}
        </Box>
      )}
      <Flex
        w='full'
        direction='column'
        justifyContent='center'
        align='center'
        my={4}
      >
        <IconButton
          aria-label='Scroll down'
          mx='auto'
          icon={<ChevronDownIcon />}
          variant='ghost'
          size='lg'
          rounded='100%'
          onClick={onChevronClick}
        />
      </Flex>
    </Box>
  );
}
