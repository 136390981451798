import React from "react";
import { graphql, useStaticQuery } from "gatsby";


import SideBySide from "./presentation/SideBySide";
import { getImage } from "gatsby-plugin-image";


const query = graphql`
{
  componentsYaml(layout: {eq: "processes"}) {
    layout
    title
    subtitle
    processes {
      title
      subtitle
      body
      image {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      icon
    }
  }
}
`;

export default function Processes() {
  const { componentsYaml: {layout, title, subtitle, processes} } = useStaticQuery(query);
  return <SideBySide section={layout} title={title} subtitle={subtitle} content={processes.map(({image, ...props}) => ({...props, image: getImage(image)}))} />
};