import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import HeadingComponent from "./Heading";
import MarkdownParser from "../util/MarkdownParser";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  SimpleGrid,
  Fade,
} from "@chakra-ui/react";

const AccordionItemWrapper = ({ title, children }) => (
  <AccordionItem>
    <h3>
      <AccordionButton>
        <Box flex='1' textAlign='left' fontSize='3xl' fontWeight='bold'>
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h3>
    <AccordionPanel pb={4} fontSize='md'>
      {children}
    </AccordionPanel>
  </AccordionItem>
);

export default function AccordionWithImages({
  title,
  subtitle,
  section,
  content,
  fallback = <Box bg='gray.200' w={26} h={26} />,
}) {
  const [index, setIndex] = useState(0);

  const FadeItem = ({ i, image }) => (
    <Fade in={i === index} className='fade-container'>
      <Box pos='absolute'>
        {image ? <GatsbyImage image={image} alt='' /> : fallback}
      </Box>
    </Fade>
  );

  return (
    <Box maxW='6xl' px={5} mx='auto' py={{ base: 8, xl: 24 }} id={section}>
      <Box mb={20} display='grid' placeContent='center'>
        <HeadingComponent title={title} subtitle={subtitle} />
      </Box>
      <SimpleGrid
        alignItems='start'
        columns={{ base: 1, md: 2 }}
        my={{ base: 8, xl: 24 }}
        spacingY={{ base: 10, md: 52 }}
        spacingX={{ base: 10, md: 24 }}
        flexDirection='column'
        h='max-content'
      >
        <Box
          pos='relative'
          h={{ base: "sm", lg: "md" }}
          justifySelf='center'
          w='max-content'
          sx={{ ".fade-container": { width: { base: "sm", lg: "lg" } } }}
        >
          {content.map(({ image }, i) => (
            <FadeItem key={i} i={i} image={image} />
          ))}
        </Box>
        <Accordion
          defaultIndex={index}
          flex={1}
          minW='20rem'
          h='max-content'
          onChange={(val) => setIndex(val)}
        >
          {content.map(({ title, body }, i) => (
            <AccordionItemWrapper title={title} key={i}>
              <MarkdownParser
                lineHeight='short'
                sx={{ "& p + p": { marginTop: "0.5em" } }}
                src={body}
              />
            </AccordionItemWrapper>
          ))}
        </Accordion>
      </SimpleGrid>
    </Box>
  );
}
