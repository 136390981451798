import React from "react";
import { Box, Divider, Flex } from "@chakra-ui/react";

import Heading from "./Heading";
import QuoteWall from "./QuoteWall";
import ImageList from "./ImageList";

export default function TestimonialsWrapper({
  layout,
  title,
  subtitle,
  testimonials,
  clients,
}) {
  return (
    <Box id={layout} py={{ base: 8, xl: 20 }} maxW='6xl' mx='auto'>
      <Box pt={10} px={8} display='grid' placeContent='center'>
        <Heading title={title} subtitle={subtitle} />
      </Box>
      <QuoteWall quotes={testimonials} />
      <Divider my={20} />
      <Flex
        alignItems='center'
        justifyContent='center'
        flexFlow='row wrap'
        gap={{ base: 10, md: 20 }}
        h='max-content'
        my={20}
        maxW='6xl'
        mx='auto'
      >
        <ImageList content={clients} />
      </Flex>
    </Box>
  );
}
