import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import TestimonialsWrapper from "./presentation/TestimonialsWrapper";

const query = graphql`
  {
    componentsYaml(layout: { eq: "testimonials" }) {
      layout
      title
      subtitle
      testimonials {
        body
        author
        role
      }
      clients {
        title
        alt
        logo {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;

export default function TestimonialsPage() {
  const {
    componentsYaml: { layout, title, subtitle, testimonials, clients },
  } = useStaticQuery(query);
  return (
    <TestimonialsWrapper
      layout={layout}
      title={title}
      subtitle={subtitle}
      testimonials={testimonials}
      clients={clients.map(({ logo, ...props }) => ({
        ...props,
        logo: getImage(logo),
      }))}
    />
  );
}
