import React from "react";
import { getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

import AccordionWithImages from "./presentation/AccordionWithImages";

const query = graphql`
  {
    componentsYaml(layout: { eq: "advantages" }) {
      layout
      title
      subtitle
      advantages {
        title
        body
        image {
          childImageSharp {
            gatsbyImageData(
              width: 750
              quality: 75
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;

export default function Advantages() {
  const {
    componentsYaml: { layout, title, subtitle, advantages },
  } = useStaticQuery(query);
  return (
    <AccordionWithImages
      section={layout}
      title={title}
      subtitle={subtitle}
      content={advantages.map(({ image, ...props }) => ({
        ...props,
        image: getImage(image),
      }))}
    />
  );
}
