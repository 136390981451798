import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Hero from "./presentation/Hero";
import { getImage } from "gatsby-plugin-image";

const query = graphql`
  {
    componentsYaml(layout: { eq: "hero" }) {
      title
      subtitle
      chevronTitle
      chevronSubtitle
      layout
      image {
        childImageSharp {
          gatsbyImageData(
            width: 600
            quality: 75
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

export default function HeroComponent() {
  const {
    componentsYaml: {
      layout,
      title,
      subtitle,
      chevronTitle,
      chevronSubtitle,
      image,
    },
  } = useStaticQuery(query);
  return (
    <Hero
      section={layout}
      title={title}
      subtitle={subtitle}
      image={getImage(image)}
      onChevronClick={() =>
        document.getElementById("processes").scrollIntoView()
      }
      chevronTitle={chevronTitle}
      chevronSubtitle={chevronSubtitle}
    />
  );
}
